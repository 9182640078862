import React from "react";
import Styles from "./LoadingUnpacking.module.scss";

const LoadingUnpackingView = (
	{
		visible,
	}: {
		visible: boolean,
	},
) => (
	<div className={Styles.loadingUnpacking}
	     style={{display: visible ? "flex" : "none"}}
	>
		<div className={Styles.spinner}>
			<div className={Styles.center}/>

			<div className={Styles.inner}>
				<div className={Styles.innerItem}/>
				<div className={Styles.innerItem}/>
				<div className={Styles.innerItem}/>
				<div className={Styles.innerItem}/>
			</div>

			<div className={Styles.outer}>
				<div className={Styles.outerItem}/>
				<div className={Styles.outerItem}/>
				<div className={Styles.outerItem}/>
				<div className={Styles.outerItem}/>
			</div>
		</div>
		<div>
			Loading
			<span className={Styles.loadingDot}>.</span>
			<span className={Styles.loadingDot}>.</span>
			<span className={Styles.loadingDot}>.</span>
		</div>
	</div>
);

export default LoadingUnpackingView;
