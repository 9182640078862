import React from "react";
import Styles from "./ShowCardsLayot.module.scss";

const ShowCardsLayot = ({children}: {children: React.ReactNode}) => (
	<>
		<div className={Styles.background}/>
		<div className={Styles.container}>
			{children}
		</div>
	</>
);

export default ShowCardsLayot;
