import { TRarity } from "@pro/common/conf";
import React, { useState } from "react";
import { BookModel } from "../../../stores/BookModel";
import { HeroModel } from "../../../stores/HeroModel";
import { SymbolModel } from "../../../stores/SymbolModel";
import BackBtn from "./BackBtn";
import ScaleContainerView from "./ScaleContainerView";
import UnpackedCardView from "./UnpackedCardView";
import "./ShowCardsView.scss";

function getStylesByCardsCount(length: number)
{
	if (length === 10) {
		return {styleClass: "show-cards-10", width: 1114, height: 716};
	} else if (length === 3) {
		return {styleClass: "show-cards-3", width: 1114, height: 716};
	} else {
		return {styleClass: "show-cards-1", width: 200, height: 500};
	}
}

const ShowCardsView = (
	{
		cards,
		onReturn,
		onCardsLoaded = () => {},
		visible = true,
	}: {
		cards: (SymbolModel | BookModel | HeroModel)[],
		onReturn: Function,
		onCardsLoaded?: Function,
		visible?: boolean,
	}) =>
{
	const [loaded, setLoaded] = useState(new Array<boolean>(cards.length).fill(false));
	const [flipped, setFlipped] = useState(new Array<boolean>(cards.length).fill(false));
	const [btnVisible, setBtnVisible] = useState(false);


	const onCardFlip = (index: number) => {
		const flippedState = [...flipped];
		flippedState[index] = true;
		setFlipped(flippedState);
		setBtnVisible(flippedState.every(it => it));
	};

	const onCardLoaded = (index: number) => {
		const loadedState = [...loaded];
		loadedState[index] = true;
		setLoaded(loadedState);
		console.log("loaded cards:", loaded.toString());
		if (loadedState.every(it => it))
			onCardsLoaded();
	};

	const cardViews = cards.map((it, index) =>
		<UnpackedCardView
			img={it.img}
			rarity={it.rarity as TRarity}
			onLoaded={() => onCardLoaded(index)}
			onFlipped={() => onCardFlip(index)}
			isHero={it instanceof HeroModel}
			key={`unpacked-card-${index}`}
		/>,
	);
	const styles = getStylesByCardsCount(cards.length);

	return (
		<>
			<ScaleContainerView
				width={styles.width}
				height={styles.height}
				styleClass={styles.styleClass}
				visible={visible}
			>
				{cardViews}
				<BackBtn
					onClick={() => onReturn()}
					visible={btnVisible}
				/>
			</ScaleContainerView>
		</>

	);
};

export default ShowCardsView;
