import React from "react";
import EnsureLoginedView from "../components/cabinet/EnsureLoginedView";
import UnpackingView from "../components/cabinet/unpacking/UnpackingView";
import NotificationView from "../components/NotificationView";

const UnpackingPage = () => (
	<>
		<NotificationView/>
		<EnsureLoginedView>
			<UnpackingView/>
		</EnsureLoginedView>
	</>
);

export default UnpackingPage;