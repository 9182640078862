import classNames from "classnames";
import React from "react";
import btnStyle from "../../Button.module.scss";

const BackBtn = ({onClick, visible = true}: {onClick: Function, visible?: boolean}) => (
	<button
		className={classNames(btnStyle.button, btnStyle.standard, "back-button")}
		onClick={() => onClick()}
		style={{visibility: visible ? "visible" : "hidden"}}
	>
		Back
	</button>
);

export default BackBtn;
