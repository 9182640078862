import { observer } from "mobx-react";
import React, { useState } from "react";
import { useApp } from "../../../App";
import Button from "../../Button";
import Style from "./GetCardsView.module.scss";

const GetCardsView = observer(() => {
	let app = useApp();
	const [btnReady, setBtnReady] = useState(true);

	const onGetCardsClick = async () => {
		try {
			setBtnReady(false);
			const lastAsset = await app.eos.aaContract.findLastAsset(app.store.userName);
			await app.backendService.unpack(app.store.userName);
			await app.worldService.updateUnpackedAssets(lastAsset?.asset_id ?? 0);
		} catch (e) {
			app.showError(e.message ?? e);
			setBtnReady(true);
		}
	};

	return (
		<div className={Style.container}>
			<div className={Style.pack_box}>
				<img src={app.store.unpacking?.packImg} alt="pack image"/>
			</div>
			<Button
				text="Get Cards"
				onClick={() => onGetCardsClick()}
				ready={btnReady}
			/>
		</div>
	);
});

export default GetCardsView;
