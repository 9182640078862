import { TRarity } from "@pro/common/conf";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useApp } from "../../../App";
import backImg from "../../../images/back_cards.png";
import Styles from "./UnpackedCard.module.scss";
import ShadowStyles from "./UnpackedCardShadows.module.scss";

const shadowClasses = {
	common: ShadowStyles.common,
	rare: ShadowStyles.rare,
	unique: ShadowStyles.unic,
	epic: ShadowStyles.epic,
	legendary: ShadowStyles.legendary,
	mythic: ShadowStyles.mythic,
};

const UnpackedCardView = (
	{
		img,
		rarity,
		onLoaded = () => {},
		onFlipped = () => {},
		flipped = false,
		isHero = false,
	}: {
		img: string,
		rarity: TRarity,
		onLoaded?: Function,
		onFlipped?: Function,
		flipped?: boolean,
		isHero: boolean,
	}) =>
{
	const app = useApp();
	const shadowClass = shadowClasses[rarity] ?? shadowClasses.common;

	const [frontImgLoaded, setFrontImgLoaded] = useState(false);
	const [backImgLoaded, setBackImgLoaded] = useState(false);
	const [isFlipped, setFlipped] = useState(flipped);

	useEffect(() => {
		if (frontImgLoaded && backImgLoaded)
			onLoaded();
	}, [frontImgLoaded, backImgLoaded]);

	const onClick = () => {
		onFlipped();
		setFlipped(true);
		app.audioService.playUnpackFx(rarity);
	};

	return (
		<div
			className={classNames(Styles.container, {[Styles.flipScaleUpVer]: isFlipped}, "unpacked-card")}
			onClick={() => onClick()}
		>
			<img
				className={classNames(Styles.image, Styles.front, {[Styles.noShadow]: isHero})}
				src={backImg}
				onLoad={() => setFrontImgLoaded(true)}
				draggable="false"
				alt="card back image"
			/>
			<div
				className={classNames(
					Styles.back, Styles.image,
					ShadowStyles.shadow, shadowClass, {[ShadowStyles.heroShadow]: isHero},
				)}
			>
				<img
					className={classNames(Styles.image, {[Styles.heroImage]: isHero})}
					src={img}
					onLoad={() => setBackImgLoaded(true)}
					draggable="false"
					alt="card image"
				/>
			</div>
		</div>
	);
};

export default UnpackedCardView;
