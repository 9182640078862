import React, { useEffect, useState } from "react";

const ScaleContainerView = (
	{
		width,
		height,
		children,
		styleClass,
		visible = true,
	}: {
		width: number,
		height: number,
		children: React.ReactNode,
		styleClass: string,
		visible: boolean,
	}) =>
{
	let [scale, setScale] = useState(1);

	const updateWidthAndHeight = () => {
		const scaleX = window.innerWidth / width;
		const scaleY = window.innerHeight / height;
		const newScale = scaleX < scaleY ? scaleX : scaleY;
		setScale(newScale);
	};

	useEffect(() => {
		window.addEventListener("resize", updateWidthAndHeight);
		return () => window.removeEventListener("resize", updateWidthAndHeight);
	});

	useEffect(() => updateWidthAndHeight(), []);

	return (
		<div
			className={styleClass}
			style={{
				width: `${width}px`,
				height: `${height}px`,
				transform: `scale(${scale}`,
				position: "relative",
				display: visible ? "block" : "none"
			}}
		>
			<div
				style={{
					width: `${width}px`,
					height: `${height}px`,
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default ScaleContainerView;