import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useApp } from "../../../App";
import { navigateTo, PagePath } from "../../../helpers/PageHelper";
import GetCardsView from "./GetCardsView";
import ShowCardsContainerView from "./ShowCardsContainerView";

const UnpackingView = observer(() => {
	let app = useApp();

	const loadData = async () => {
		await app.worldService.updatePackAndAsset();
		if (!app.store.unpacking) {
			await navigateTo(PagePath.PACKS);
		}
	};

	useEffect(() => {
		if (!app.store.unpacking)
			loadData().catch();
	}, []);

	return app.store.unpacking?.assetsObtained
		? <ShowCardsContainerView/>
		: <GetCardsView/>;
});

export default UnpackingView;