import React, { useEffect, useRef, useState } from "react";
import Styles from "./UnpackVideo.module.scss";

const VIDEO_SRC = "https://static.tribalbooks.io/videos/unpacking.mp4";

const UnpackVideo = (
	{
		playVideo,
		onVideoLoaded,
		onTimeToShowCards,
	}: {
		playVideo: boolean,
		onVideoLoaded: Function,
		onTimeToShowCards: Function,
	}) =>
{
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (playVideo && videoRef.current)
			videoRef.current.play()
				.catch(e => console.error("can't play video:", e.message));
	}, [playVideo]);

	useEffect(() => {
		const interval = setInterval(() => {
			const currentTime = videoRef.current?.currentTime ?? 0;
			if (currentTime > 6.5) {
				onTimeToShowCards();
				clearInterval(interval);
			}

		}, 100);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className={Styles.container}>
			<video
				ref={videoRef}
				src={VIDEO_SRC}
				onLoadedData={() => onVideoLoaded()}
			/>
		</div>
	);
};

export default UnpackVideo;
