import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useApp } from "../../../App";
import LoadingUnpackingView from "./LoadingUnpackingView";
import ShowCardsLayot from "./ShowCardsLayot";
import ShowCardsView from "./ShowCardsView";
import UnpackVideo from "./UnpackVideo";

const ShowCardsContainerView = () => {
	let app = useApp();

	const onReturnClick = async () => {
		app.store.removeUnpacking();
		await navigate(-1)
		// await navigateTo(PagePath.PACKS);
	};

	const [videoLoaded, setVideoLoaded] = useState(false);
	const [cardLoaded, setCardLoaded] = useState(false);
	const [audioLoaded, setAudioLoaded] = useState(app.audioService.fxLoaded);
	const [allLoaded, setAllLoaded] = useState(false);
	const [showCards, setshowCards] = useState(false);

	useEffect(() => {
		app.audioService.loadAllFx()
			.then(() => setAudioLoaded(app.audioService.fxLoaded))
			.catch(e => console.error(e));
	}, []);

	useEffect(() => {
		console.log("loaded video:", videoLoaded,
			", cards:", cardLoaded,
			", audio:", audioLoaded,
		);

		if (videoLoaded && cardLoaded && audioLoaded)
			setAllLoaded(true);
	}, [videoLoaded, cardLoaded, audioLoaded]);

	useEffect(() => {
		if (showCards)
			app.audioService.playBg();
		return () => app.audioService.stopBg();
	}, [showCards]);

	return (
		<>
			<LoadingUnpackingView visible={!allLoaded}/>
			<UnpackVideo
				playVideo={allLoaded}
				onVideoLoaded={() => setVideoLoaded(true)}
				onTimeToShowCards={() => setshowCards(true)}
			/>
			<ShowCardsLayot>
				<ShowCardsView
					cards={app.store.unpacking!.cards}
					onReturn={() => onReturnClick()}
					onCardsLoaded={() => setCardLoaded(true)}
					visible={showCards}
				/>
			</ShowCardsLayot>
		</>
	);
};
export default ShowCardsContainerView;
